import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    contactForm: FormGroup;

    constructor(
        private viewportScroller: ViewportScroller,
        private fb: FormBuilder,
        private http: HttpClient) {

        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            subject: ['', [Validators.required]],
            number: [''],
            message: ['', Validators.required]
        });
    }

    submit(form){
        if (form.valid) {
            const formData = this.contactForm.value;

            this.http.post('https://formspree.io/f/xgvwakye', formData).subscribe(
                () => this.resetForm()
            );
        }
    }

    resetForm() {
        this.contactForm.reset({
            name: '',
            email: '',
            subject: '',
            number: '',
            message: ''
        })
    }
}
