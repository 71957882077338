<div id="sponsors" class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h2>A HUGE thank you to our <span>sponsors!</span></h2>
                <p>Enormous gratitude to our incredible sponsors — you help make all of this possible</p>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6">
                    <div class="about-video">
                        <img src="assets/img/sneyds-sponsor.jpg" alt="sneyds sponsor">
                        <div class="video-btn">
                            <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="about-content">
                        <div class="section-title">
                            <img class="mb-4" src="assets/img/sneyds-logo.png" alt="sneyds logo">
                            <p>Snyeds wonder dog is a great product, keeping our dogs in perfect health. Sneyds is a family run business based in Scunthorpe, check out their website for a great range of foods to suit every dogs needs.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 container">
                    <div class="about-content">
                        <div class="section-title">
                            <div class="csj-container ">
                                <img class="mt-4" src="assets/img/csj-logo.png" alt="csj logo">
                            </div>
                            <p class="bottom-text">Thank you for your support. CSJ is a British company that supplies a wide range of Supplements herbal and natural to help keep your dogs in tip top condition. Check out their website for all of their range.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="about-video">
                        <img src="assets/img/csj-sponsor.jpg" alt="csj sponsor">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
