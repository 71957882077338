<div id="welcome" class="skill-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Welcome to the world of <span>Marshkeeper Gundogs</span></h2>
            <p>Where we meticulously breed and expertly train top-quality cocker and springer spaniels.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>We Are Passionate About <span>Gundogs</span></h2>
                        <p>At Marshkeeper Gundogs, we firmly believe that training your dog should be a profoundly rewarding experience—for both you and your furry companion. Whether you’re honing obedience skills or harboring dreams of field trials, this journey is one you embark upon together—a shared adventure that weaves an unbreakable bond destined to last a lifetime.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/IMG_3777.jpg" alt="about">
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
