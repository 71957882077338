<footer class="footer-area">
    <div class="container">
        <img src="assets/img/mkg-logo.png" alt="image">
        <span>Copyright - MarshkeeperGundogs - 2024</span>
        <!--<h3><a routerLink="/"><span>X</span>avro</a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>-->
    </div>
</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>
