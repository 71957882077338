import {Component, OnInit} from '@angular/core';
import {GalleryItem, GalleryModule, ImageItem} from "ng-gallery";
import {GallerizeDirective} from "ng-gallery/lightbox";

@Component({
  selector: 'app-gallery',
  standalone: true,
    imports: [GalleryModule, GallerizeDirective],
  styleUrl: './gallery.component.scss',
  template: `<gallery gallerize  [items]="images" thumbPosition="top" thumbImageSize="cover"></gallery>`
})
export class GalleryComponent implements OnInit {
    images: GalleryItem[];

    ngOnInit() {
        // Set items array
        this.images = [
            new ImageItem({ src: 'IMG_8551.jpg', thumb: 'IMG_8551.jpg' }),
            new ImageItem({ src: 'IMG_8565.jpg', thumb: 'IMG_8565.jpg' }),
            new ImageItem({ src: 'IMG_8598.jpg', thumb: 'IMG_8598.jpg' }),
            new ImageItem({ src: 'IMG_8605.jpg', thumb: 'IMG_8605.jpg' }),
            new ImageItem({ src: 'IMG_8642.jpg', thumb: 'IMG_8642.jpg' }),
            new ImageItem({ src: 'blog1-main.jpg', thumb: 'blog1-main.jpg' }),
            new ImageItem({ src: 'blog1-content.jpg', thumb: 'blog1-content.jpg'}),
            new ImageItem({ src: 'IMG_3800.jpg', thumb: 'IMG_3800.jpg'}),
            new ImageItem({ src: 'IMG_2438.jpg', thumb: 'IMG_2438.jpg'})
        ]
    }
}
``
