<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Want to find out more?</h4>
            <h2><span>Contact</span> Us</h2>
            <p>Feel free to use the form below to reach out if you have any questions about our services. Can't wait to hear from you!</p>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="contact-form">
                    <form [formGroup]="contactForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name*</label>
                                    <input required minlength="3" maxlength="50" formControlName="name" type="text" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="contactForm.get('name').touched && !contactForm.get('name').valid">
                                        <div *ngIf="contactForm.get('name').errors.required">Name is required.</div>
                                        <div *ngIf="contactForm.get('name').errors.minlength">Name is minimum {{ contactForm.get('name').errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="contactForm.get('name').errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email*</label>
                                    <input required formControlName="email" name="email" type="text" class="form-control" id="email" >
                                    <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)">
                                        <div class="alert alert-danger" *ngIf="contactForm.get('email').errors.required">Email is required.</div>
                                        <div class="alert alert-danger" *ngIf="contactForm.get('email').errors.email">Invalid email format.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject*</label>
                                    <input required formControlName="subject" type="text" class="form-control" id="subject">
                                    <div class="alert alert-danger" *ngIf="contactForm.get('subject').touched && !contactForm.get('subject').valid">Subject is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input formControlName="number" type="text" class="form-control" id="number">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message*</label>
                                    <textarea required formControlName="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="contactForm.get('message').touched && !contactForm.get('message').valid">Message is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [disabled]="contactForm.invalid" (click)="submit(contactForm)">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
