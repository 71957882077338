<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Our News</h4>
            <h2>Latest Blog <span>Posts</span></h2>
            <p>Here you will find the latest news and upcoming event.</p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Crufts 2019</span>
                        <h3><a routerLink="/chatsworth-2024">Marshkeeper Gundogs Winning at Crufts 2019</a></h3>
                        <p>We are back again at the beautiful Chatworth country fair this September...</p>
                        <a routerLink="/chatsworth-2024" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Chatsworth 2022</span>
                        <h3><a routerLink="/chatsworth-2024">We are going to Chatsworth country fair 2022</a></h3>
                        <p>We are back again at the beautiful Chatworth country fair this September...</p>
                        <a routerLink="/chatsworth-2024" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Chatsworth 2024</span>
                        <h3><a routerLink="/chatsworth-2024">We are back again at Chatsworth country fair 2024</a></h3>
                        <p>We are back again at the beautiful Chatworth country fair this September...</p>
                        <a routerLink="/chatsworth-2024" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
<!--                <ng-template carouselSlide>-->
<!--                    <div class="single-blog-item bg2">-->
<!--                        <span>Tech</span>-->
<!--                        <h3><a routerLink="/blog-details">Get your first business suit right with these tips</a></h3>-->
<!--                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>-->
<!--                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>-->
<!--                    </div>-->
<!--                </ng-template>-->
<!--                <ng-template carouselSlide>-->
<!--                    <div class="single-blog-item bg3">-->
<!--                        <span>Design</span>-->
<!--                        <h3><a routerLink="/blog-details">How to Make a Font - Font Design Full Process</a></h3>-->
<!--                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>-->
<!--                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>-->
<!--                    </div>-->
<!--                </ng-template>-->
<!--                <ng-template carouselSlide>-->
<!--                    <div class="single-blog-item bg4">-->
<!--                        <span>Marketing & Trend</span>-->
<!--                        <h3><a routerLink="/blog-details">10 Hot Marketing Trends You Need to Implement</a></h3>-->
<!--                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>-->
<!--                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>-->
<!--                    </div>-->
<!--                </ng-template>-->
<!--                <ng-template carouselSlide>-->
<!--                    <div class="single-blog-item bg5">-->
<!--                        <span>Game</span>-->
<!--                        <h3><a routerLink="/blog-details">How to Be a Work From Home Professional</a></h3>-->
<!--                        <p> Fusce vitae nulla at lorem pret ium semper. Curab itur laoreet, lectus ac digni ssim vest ibul lorem pretium semper lectus ac digni...</p>-->
<!--                        <a routerLink="/blog-details" title="Read More" class="link-btn"><i class="fas fa-arrow-right"></i></a>-->
<!--                    </div>-->
<!--                </ng-template>-->
            </owl-carousel-o>
        </div>
    </div>
</div>
