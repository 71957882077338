<div id="gallery" class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Out in the field</h4>
                        <h2>See us <span>Working </span></h2>
                        <p>Keep your eyes peeled and your curiosity piqued as we continue to proudly showcase our remarkable working dogs, unveil the intricacies of our training methods, and host captivating events that celebrate the bond between us and our loyal canine companions.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <app-gallery></app-gallery>
            </div>
        </div>
    </div>
</div>
