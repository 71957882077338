import { Component } from '@angular/core';
import {GalleryComponent} from "../gallery/gallery.component";

@Component({
  selector: 'app-content',
  standalone: true,
    imports: [
        GalleryComponent
    ],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss'
})
export class ContentComponent {

}
