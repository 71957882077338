import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-chatsworth-2024',
  standalone: true,
    imports: [
        RouterLink
    ],
  templateUrl: './chatsworth-2024.component.html',
  styleUrl: './chatsworth-2024.component.scss'
})
export class Chatsworth2024Component {

}
