<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>What We <span>Do</span></h2>
            <p>We offer a diverse range of services, from training to exciting events, with even more on the horizon. Stay tuned and keep your eyes peeled for what’s next!</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-training.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Training</h3>
                        <p>Whether you prefer one-on-one training or enjoy learning alongside others, we offer a variety of options tailored to help you progress at your own pace. Our programs are designed to be both enjoyable and rewarding, ensuring a fulfilling training experience.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-events.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Events</h3>
                        <p>Add a spark to your next event with our engaging and educational gundog demonstrations! Watch as our talented dogs showcase their impressive abilities, while we share handy hints and tricks to help you along the way.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-stud.jpg" alt="services-img">
                    </div>
                    <div class="services-content">
                        <h3>Dogs For Stud</h3>
                        <p>Whether you’re looking to enhance your breeding program or seeking a sire with exceptional qualities, Marshkeeper gundogs are the perfect choice. Inherit their remarkable traits, promising a future generation of outstanding gundogs.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
